import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {fromLonLat} from 'ol/proj';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {getVectorContext} from 'ol/render';
import {easeOut} from 'ol/easing';
import {unByKey} from 'ol/Observable';
import Overlay from 'ol/Overlay';
import fetch from 'node-fetch';

import $ from 'jquery'; 

const tileLayer = new TileLayer({
  source: new OSM({
    wrapX: true,
  }),
});

const source = new VectorSource({
  wrapX: false,
});
const vector = new VectorLayer({
  source: source,
  style: function (feature) {
    if(feature.get('status') == 1){
      return styles['upcoming'];
    }else if(feature.get('status') == 2){
      return styles['new'];
    }else{
      return styles['sold'];
    }
    
  },
});

const view = new View({
  center: fromLonLat([0,0]),
  zoom: 2, 
});

const map = new Map({
  target: 'map',
  layers: [tileLayer, vector],
  view: view,
});

function getMaxVisibleCategory(newZoom){
  if(newZoom < 4.2){
    return 5;
  }else if(currZoom < 8){
    return 4;
  }else if(currZoom < 12){
    return 3;
  }else if(currZoom < 15){
    return 2;
  }else if(currZoom < 17){
    return 1;
  }
}

// *********** ADD CITIES ************

const styles = {
  'upcoming': new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({color: '#dcf208'}),
      stroke: new Stroke({color: '#adbe07'}),
    }),
  }),
  'new': new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({color: '#e04f28'}),
      stroke: new Stroke({color: '#873018'}),
    }),
  }),
  'sold': new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({color: '#1868B7'}),
      stroke: new Stroke({color: '#1868B7'}),
    }),
  }),
};

function addCity(city)
{
    var lat = city.latitude;
    var long = city.longitude;
    const geom = new Point(fromLonLat([long, lat]));
    const feature = new Feature({
      geometry : geom,
      id : city.id,
      name : city.name,
      population: city.population,
      country: city.country,
      status: city.status,
      category: city.category,
      nft: city.nft
    });
    
    source.addFeature(feature);
}

var cities = require('./data/cities.json');
function addCities(){
  cities["cities"].forEach(city => {
    if(city.category == 5){
      addCity(city, false);
    }
  });
}

var currZoom = map.getView().getZoom();
map.on('moveend', function(e) {
  var newZoom = map.getView().getZoom();
  var maxVisibleCategory = getMaxVisibleCategory(newZoom);
  var oldMaxVisibleCategory = getMaxVisibleCategory(currZoom);
  if (oldMaxVisibleCategory != maxVisibleCategory) {
    if(currZoom < newZoom){
      // Zoom in
      console.log(newZoom);
      cities["cities"].forEach(city =>{
        if(city.category < oldMaxVisibleCategory && city.category >= maxVisibleCategory){
          addCity(city);
        }
      });
    }else{
      source.getFeatures().forEach(feature => {
        if(feature.get('category') < maxVisibleCategory){
          source.removeFeature(feature);
        }
      });
    }    
    currZoom = newZoom;
  }
});

//******************* EARTHQUAKE ************************* */

const duration = 2000;
function flash(feature) {
  const start = Date.now();
  const flashGeom = feature.getGeometry().clone();
  const listenerKey = tileLayer.on('postrender', animate);

  map.render();

  function animate(event) {
    //console.log("animate");
    const frameState = event.frameState;
    const elapsed = frameState.time - start;
    if (elapsed >= duration) {
      unByKey(listenerKey);
      return;
    }
    const vectorContext = getVectorContext(event);
    const elapsedRatio = elapsed / duration;
    // radius will be 5 at start and 30 at end.
    const radius = easeOut(elapsedRatio) * 50 + 5;
    const opacity = easeOut(1 - elapsedRatio);

    var color = null;
    if(feature.get('status') == 1){
      color = 'rgba(220, 242, 8, ' + opacity + ')';   
    }else if(feature.get('status') == 2){
      color = 'rgba(224, 79, 40, ' + opacity + ')';
    }else{
      color = 'rgba(24, 104, 183, ' + opacity + ')';
    }
    const style = new Style({
      image: new CircleStyle({
        radius: radius,
        stroke: new Stroke({
          color: color,
          width: 0.25 + opacity,
        }),
      }),
    });

    vectorContext.setStyle(style);

    vectorContext.drawGeometry(flashGeom);
    // tell OpenLayers to continue postrender animation
    map.render();
  }
}

source.on('addfeature', function (e) {
  //flash(e.feature);
});

//******************* INFO BOX ************************* */

const element = document.getElementById('info');

const infoBox = new Overlay({
  element: element,
  positioning: 'bottom-center',
  stopEvent: true,
});

function showInfoBox(feature){
  map.addOverlay(infoBox);
  infoBox.setPosition(feature.getGeometry().getCoordinates());
  

  if(feature.get('status') != 1){
    var nft = feature.get("nft");
    let url = "https://api.opensea.io/api/v1/asset/" + nft.contract_address + "/" + nft.token_id;
    let settings = { method: "Get" };

    fetch(url, settings)
        .then(res => res.json())
        .then((asset) => {
          populateInfoBox(feature, asset, false);          
        });
  }else{
    populateInfoBox(feature, null, true);
  }
}

function populateInfoBox(feature, asset, upcoming){
  
  const div_info_header = document.getElementById('div_info_header');
  const div_owner_name = document.getElementById('div_owner_name');
  const div_owner_address = document.getElementById('div_owner_address');
  const div_country = document.getElementById('div_country');
  const div_population = document.getElementById('div_population');
  const div_last_price = document.getElementById('div_last_price');
  
  div_info_header.innerHTML = '<img src=\"./img/category_' + feature.get('category') + '.png\" style=\"vertical-align:middle; padding-right:5px;\" width=\"25px\">' 
    + '<span style=\"\">' + feature.get('name') + '</span>';
  
  div_population.innerHTML = '' + feature.get('population').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  div_country.innerHTML = '' + feature.get('country');

  //console.log(asset);

  if(upcoming){
    div_owner_name.innerHTML = 'Upcoming...';
    div_last_price.innerHTML =  'Upcoming...';  

    const img_opensea_banner = document.getElementById('img_opensea_banner');
    img_opensea_banner.style.display = 'inline';
    const bt_opensea_link = document.getElementById('bt_opensea_link');
    bt_opensea_link.style.display = 'none';

  }else{
    if(asset.last_sale == null){
      div_owner_name.innerHTML = 'LetsBuyCities';
    }else if(asset.owner.user == null || asset.owner.user.username == 'NullAddress' || asset.owner.user.username == null){
      div_owner_name.innerHTML = 'Anonymous';
    }else{
      div_owner_name.innerHTML = '' + asset.owner.user.username;
    }
    if(asset.owner.address == null){
      div_owner_address.innerHTML = 'Error';
    }else{
      if(asset.is_presale){
        div_owner_address.innerHTML = '';
      }else{
        var owner_address_truncated = asset.owner.address.substring(0,5) + '...' + asset.owner.address.substring(36,41);
        var etherscan_URL = 'https://etherscan.io/address/';
        div_owner_address.innerHTML = '<a href=\"' + etherscan_URL + asset.owner.address + ' \" target="_blank">' + owner_address_truncated + '</a>';
      }
    }
    if(asset.last_sale != null){
      if(asset.last_sale.total_price == "0"){
        div_last_price.innerHTML = "0";
      }else{
        var payment_token = asset.last_sale.payment_token;
        if(payment_token.symbol == "ETH"){
          var price_in_ETH = (asset.last_sale.total_price / Math.pow(10,payment_token.decimals));
          //div_last_price.innerHTML =  price_in_ETH + ' ' + '<img src=\"./img/ETH.svg\" style=\"padding-left:5px;\" height=\"20\">';
          div_last_price.innerHTML =  price_in_ETH + ' ETH';
        }else if(payment_token.symbol == "WETH"){
          var price_in_WETH = (asset.last_sale.total_price / Math.pow(10,payment_token.decimals));
          //div_last_price.innerHTML =  price_in_WETH + ' ' + '<img src=\"./img/WETH.svg\" style=\"padding-left:5px;\" height=\"20\">';
          div_last_price.innerHTML =  price_in_WETH + ' WETH';
        }
        var usd_price = payment_token.usd_price;
        if(usd_price != undefined && usd_price != null){
          const div_last_price_usd = document.getElementById('div_last_price_usd');
          div_last_price_usd.innerHTML = (Math.round(usd_price * 100) / 100).toFixed(2) + ' USD';
        }
      }
    }else{
      if(asset.is_presale){
        div_last_price.innerHTML = 'Upcoming...';
      }else{
        div_last_price.innerHTML = 'Not traded yet';
      }
    }
    
    const bt_opensea_link = document.getElementById('bt_opensea_link');
    bt_opensea_link.style.display = 'inline';
    bt_opensea_link.onclick = function(){
      window.open(asset.permalink, '_blank').focus();
    }
    const img_opensea_banner = document.getElementById('img_opensea_banner');
    img_opensea_banner.style.display = 'none';
    
    
  }

  // Workaround for info marker
  const div_arrow = document.getElementById('div_arrow');
  if(div_arrow == null){
    console.log("Error");
  }else{
    div_arrow.addEventListener('click', function (event) {
      depopulateInfoBox();
      map.removeOverlay(infoBox);
      });
  }  
  
}

function depopulateInfoBox(){
  const div_info_header = document.getElementById('div_info_header');
  const div_owner_name = document.getElementById('div_owner_name');
  const div_owner_address = document.getElementById('div_owner_address');
  const div_country = document.getElementById('div_country');
  const div_population = document.getElementById('div_population');
  const div_last_price = document.getElementById('div_last_price');
  const div_last_price_usd = document.getElementById('div_last_price_usd');
  if(div_info_header != null) div_info_header.innerHTML = '';
  if(div_owner_name != null) div_owner_name.innerHTML = '';
  if(div_owner_address != null) div_owner_address.innerHTML = '';
  if(div_country != null) div_country.innerHTML = '';
  if(div_population != null) div_population.innerHTML = '';
  if(div_last_price != null) div_last_price.innerHTML = '';
  if(div_last_price_usd != null) div_last_price_usd.innerHTML = '';
  const bt_opensea_link = document.getElementById('bt_opensea_link');
  if(bt_opensea_link != null){
    bt_opensea_link.style.display = 'none';
  }

  const img_opensea_banner = document.getElementById('img_opensea_banner');
  if(img_opensea_banner != null){
    img_opensea_banner.style.display = 'none';
  }
} 

// *********************** FLY TO *******************************

function flyTo(location, done) {
  const duration = 1500;
  const zoom = view.getZoom();
  let parts = 2;
  let called = false;
  function callback(complete) {
    --parts;
    if (called) {
      return;
    }
    if (parts === 0 || !complete) {
      called = true;
      done(complete);
    }
  }
  view.animate(
    {
      center: location,
      duration: duration,
    },
    callback
  );
  view.animate(
    {
      zoom: zoom - 1,
      duration: duration / 2,
    },
    {
      zoom: zoom,
      duration: duration / 2,
    },
    callback
  );
}


// *********** ON POINTER MOVE **************

var feature_onHoverOld = null;
map.on('pointermove', function (e) {
  
  var feature_onHoverNew = map.forEachFeatureAtPixel(e.pixel, function (feature) {
    return feature;
  }, {hitTolerance: 20});

  if (feature_onHoverNew !== undefined){
    map.getTargetElement().style.cursor = 'pointer';
    if(feature_onHoverOld == null || feature_onHoverNew.get("name") != feature_onHoverOld.get("name")){
      flash(feature_onHoverNew);
      feature_onHoverOld = feature_onHoverNew;
    }
  }else{
    map.getTargetElement().style.cursor = '';
    feature_onHoverOld = null;
  }     

});

// ************* ON CLICK ********************

map.on('click', function (evt) {
  const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    return feature;
  }, {hitTolerance: 20});
  if (feature) {
    depopulateInfoBox();
    var geometry = feature.getGeometry();
    var coordinates = geometry.getCoordinates();
    flyTo(coordinates, function(){});
    showInfoBox(feature);
  } else {
    depopulateInfoBox();
    map.removeOverlay(infoBox);
  }
});

// ************* STARTUP ***************************

var starting = true;
map.once('postrender', function(event) {
  addCities();
});

$(function() {
  
  $('#preloader').fadeOut('slow', function () {
    $(this).remove();
  });

  // CHECK URL PARAMETERS

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if(urlParams != null){
    const city = urlParams.get('city')
    var features = source.getFeatures();
    for (const feature of features) {
      if(feature.get('id') == city){
        var geometry = feature.getGeometry();
        var coordinates = geometry.getCoordinates();
        flyTo(coordinates, function(){});
        showInfoBox(feature);
      }
    } 
  }

  // Setup menu

  const div_about = document.getElementById('div_about');
  const div_faq = document.getElementById('div_faq');
  const div_social = document.getElementById('div_social');
  const div_contact = document.getElementById('div_contact');

  var popup_about = document.querySelector('#popup_about');
  var popup_faq = document.querySelector('#popup_faq');
  var popup_social = document.querySelector('#popup_social');
  var popup_contact = document.querySelector('#popup_contact');

  div_about.addEventListener('click', function (event) {
    depopulateInfoBox();
    map.removeOverlay(infoBox);
    popup_about.style.display='flex';
  });

  div_faq.addEventListener('click', function (event) {
    depopulateInfoBox();
    map.removeOverlay(infoBox);
    popup_faq.style.display='flex';
  });

  div_social.addEventListener('click', function (event) {
    depopulateInfoBox();
    map.removeOverlay(infoBox);
    popup_social.style.display='flex';
    popup_social.dispatchEvent(new Event('change'));

  });

  div_contact.addEventListener('click', function (event) {
    depopulateInfoBox();
    map.removeOverlay(infoBox);
    popup_contact.style.display='flex';
  });

  
  document.body.addEventListener('click', function (event) {
    var popup_about_inner = document.getElementById('popup_about_inner');
    if (window.getComputedStyle(popup_about).display === "flex" && 
        !(popup_about_inner.contains(event.target)) &&
        !(div_about.contains(event.target))){
      popup_about.style.display='none';
    }
    var popup_faq_inner = document.getElementById('popup_faq_inner');
    if (window.getComputedStyle(popup_faq).display === "flex" && 
        !(popup_faq_inner.contains(event.target)) && 
        !(div_faq.contains(event.target))){
      popup_faq.style.display='none';
    }     
    var popup_social_inner = document.getElementById('popup_social_inner');
    if (window.getComputedStyle(popup_social).display === "flex" && 
        !(popup_social_inner.contains(event.target)) && 
        !(div_social.contains(event.target))){
      popup_social.style.display='none';
      document.getElementById('twitter_container').innerHTML = '';
    }   
    var popup_contact_inner = document.getElementById('popup_contact_inner');
    if (window.getComputedStyle(popup_contact).display === "flex" && 
        !(popup_contact_inner.contains(event.target)) && 
        !(div_contact.contains(event.target))){
      popup_contact.style.display='none';
    }    
  });

  // Setup FAQ section
  var coll = document.getElementsByClassName("collapsible");
  var i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var content = this.nextElementSibling;
      if (content.style.maxHeight){
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      } 
    });
  }
  
});

