{
    "cities" : [
        {
            "id" : 55,
            "name" : "Kuala Lumpur",
            "country" : "Malaysia",
            "population" : 1790000,
            "latitude" : 3.1478,
            "longitude" : 101.6953,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753794244649418753"
            }
            
        },
        {
            "id" : 22,
            "name" : "Osaka",
            "country" : "Japan",
            "population" : 2691185,
            "latitude" : 34.75,
            "longitude" : 135.4601,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753800841719185409"
            }
        },
        {
            "id" : 555,
            "name" : "Doha",
            "country" : "Qatar",
            "population" : 2382000,
            "latitude" : 25.3,
            "longitude" : 51.5333,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753798642695929857"
            }
        },
        {
            "id" : 283,
            "name" : "Rome",
            "country" : "Italy",
            "population" : 2860009,
            "latitude" : 41.8931,
            "longitude" : 12.4828,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753799742207557633"
            }
        },
        {
            "id" : 81,
            "name" : "Santiago de Chile",
            "country" : "Chile",
            "population" : 5220161,
            "latitude" : -33.445992,
            "longitude" : -70.667061,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753797543184302081"
            }
        },
        {
            "id" : 4,
            "name" : "Mumbai",
            "country" : "India",
            "population" : 12478447,
            "latitude" : 18.9667,
            "longitude" : 72.8333,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753804140254068737"
            }
        },
        {
            "id" : 145,
            "name" : "Melbourne",
            "country" : "Australia",
            "population" : 5159211,
            "latitude" : -37.8136,
            "longitude" : 144.9631,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753796443672674305"
            }
        },
        {
            "id" : 343,
            "name" : "Accra",
            "country" : "Ghana",
            "population" : 5055900,
            "latitude" : 5.6037,
            "longitude" : -0.187,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 123,
            "name" : "Houston",
            "country" : "United States",
            "population" : 2304580,
            "latitude" : 29.7863,
            "longitude" : -95.3889,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753803040742440961"
            }
        },
        {
            "id" : 754,
            "name" : "Kingston",
            "country" : "Jamaica",
            "population" : 662435,
            "latitude" : 17.9714,
            "longitude" : -76.7931,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 379,
            "name" : "Portland",
            "country" : "United States",
            "population" : 652503,
            "latitude" : 45.5372,
            "longitude" : -122.65,
            "category" : 4,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 132,
            "name" : "Saint Petersburg",
            "country" : "Russia",
            "population" : 5351935,
            "latitude" : 59.95,
            "longitude" : 30.3167,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753801941230813185"
            }
        },
        {
            "id" : 124,
            "name" : "Atlanta",
            "country" : "United States",
            "population" : 498715,
            "latitude" : 33.7627,
            "longitude" : -84.4224,
            "category" : 4,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 1254,
            "name" : "Durban",
            "country" : "South Africa",
            "population" : 3720953,
            "latitude" : -29.8583,
            "longitude" : 31.025,
            "category" : 4,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 210,
            "name" : "Seattle",
            "country" : "United States",
            "population" : 737015,
            "latitude" : 47.6211,
            "longitude" : -122.3244,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 41,
            "name" : "Lima",
            "country" : "Peru",
            "population" : 9751717,
            "latitude" : -12.05,
            "longitude" : -77.0333,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 52,
            "name" : "Luanda",
            "country" : "Angola",
            "population" : 2571861,
            "latitude" : -8.8383,
            "longitude" : 13.2344,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 47,
            "name" : "Wuhan",
            "country" : "China",
            "population" : 11081000,
            "latitude" : 30.5872,
            "longitude" : 114.2881,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 60,
            "name" : "Hanoi",
            "country" : "Vietnam",
            "population" : 8053663,
            "latitude" : 21.0245,
            "longitude" : 105.8412,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 753,
            "name" : "Copenhagen",
            "country" : "Denmark",
            "population" : 799033,
            "latitude" : 55.6761,
            "longitude" : 12.5689,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 402,
            "name" : "Almaty",
            "country" : "Kazakhstan",
            "population" : 1977011,
            "latitude" : 43.25,
            "longitude" : 76.9,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 812,
            "name" : "Suva",
            "country" : "Fiji",
            "population" : 93970,
            "latitude" : -18.1333,
            "longitude" : 178.4333,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 728,
            "name" : "Ottawa",
            "country" : "Canada",
            "population" : 934243,
            "latitude" : 45.4247,
            "longitude" : -75.695,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 74,
            "name" : "Khartoum",
            "country" : "Sudan",
            "population" : 639598,
            "latitude" : 15.6031,
            "longitude" : 32.5265,
            "category" : 5,
            "status" : 1,
            "nft": {
                "contract_address" : "0",
                "token_id" : "0"
            }
        },
        {
            "id" : 179,
            "name" : "Johannesburg",
            "country" : "South Africa",
            "population" : 5635127,
            "latitude" : -26.2044,
            "longitude" : 28.0416,
            "category" : 5,
            "status" : 2,
            "nft": {
                "contract_address" : "0x495f947276749Ce646f68AC8c248420045cb7b5e",
                "token_id" : "58897741327755882268716869084151422209046792966157488543708753805239765696513"
            }
        }
    ]
}